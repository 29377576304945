import bff from "../../api/bff";
import IYSActions from "../../misc/IYSActions";

const state = {
  vm: null,

  packets: null,
  bankList: null,
  installments: null,

  kk_toplam_tutar: 0, // TODO: Bu mock icin (silinecek)

  payment: {
    appNo: "",
    name: "",
    surname: "",
    email: "",
    cellPhone: "",

    city: null,
    town: null,
    district: null,
    explicitAddress: null,

    installment: 1,
    paketId: 0,
    bankId: 0,

    paketName: null,
    amount: null, //Odenecek tutar

    paymentType: "CREDIT_CARD", //"CREDIT_CARD", //BANK_TRANSFER ve NONE

    creditCard: {
      cardHolderName: "",
      number: "",
      expiration: "",
      cvc: ""
    }
  }
};

const getters = {
  suggested: (state, getters, rootState) => {
    return rootState.applicant.application.paket.suggested || -1;
  },

  secilen_paket: (state, getters, rootState) => {
    return state.packets.find(item => item.id == state.payment.paketId);
  },

  secilen_banka: (state, getters, rootState) => {
    return state.bankList.find(item => item.id == state.payment.bankId);
  },

  total_price: (state, getters, rootState) => {
    return getters.secilen_paket.price;
  }
};

const mutations = {
  /**
   * Bu mock icin (silinecek)
   */
  SET_KK_TOPLAM_TUTAR: (state, val) => {
    state.kk_toplam_tutar = val;
  },

  SET_PAKET_ID: (state, paket_id) => {
    state.payment.paketId = paket_id;
  },

  SET_ODEME_TURU: (state, odeme_turu) => {
    state.payment.paymentType = odeme_turu;
  },

  SET_BANK_ID: (state, banka_id) => {
    state.payment.bankId = banka_id;
  },

  SET_PAYMENT_ADDRESS: (state, payload) => {
    state.payment.city = payload.city;
    state.payment.town = payload.town;
    state.payment.district = payload.district;
    state.payment.explicitAddress = payload.explicitAddress;
  },

  SET_PAKET_LIST: (state, payload) => {
    state.vm.$env.CONSOLE.warn(
      "SETTING PAKET LIST:",
      JSON.parse(JSON.stringify(payload))
    );
    state.packets = payload;
  },

  SET_INSTALLMENTS: (state, payload) => {
    state.vm.$env.CONSOLE.warn(
      "SETTING INSTALLMENTS:",
      JSON.parse(JSON.stringify(payload))
    );
    state.installments = payload;
  },

  SET_BANKLIST: (state, payload) => {
    state.vm.$env.CONSOLE.warn(
      "SETTING BANKLIST:",
      JSON.parse(JSON.stringify(payload))
    );
    state.bankList = payload;
  }
};

const api_call = async aw => {
  let loading = state.vm.$loading.show();
  try {
    const response = await aw;
    loading.hide();
    return response.data;
  } catch (err) {
    loading.hide();
    throw err;
  }
};

const api_call_nonbloked = async aw => {
  try {
    const response = await aw;
    return response.data;
  } catch (err) {
    throw err;
  }
};

const actions = {
  load_pakets: ({ state, commit }) => {
    if (state.packets) return;
    api_call(bff().get("/info/finance/packages"))
      .then(res => {
        commit("SET_PAKET_LIST", res.payload);
      })
      .catch(err => {
        state.vm.Alert(
          "Paket Listesi Alınamadı. Lütfen daha sonra tekrar deneyiniz."
        );
      });
  },

  load_installments: ({ state, commit }) => {
    // if (state.installments) return;
    api_call(bff().get(`/info/finance/installments/${state.payment.paketId}`))
      .then(res => commit("SET_INSTALLMENTS", res.payload))
      .catch(err => {
        commit("SET_INSTALLMENTS", null);
        state.vm.Alert(
          "Taksit Listesi Alınamadı. Lütfen daha sonra tekrar deneyiniz."
        );
      });
  },

  load_bankList: ({ state, commit }) => {
    if (state.bankList) return;
    api_call(bff().get(`/info/finance/banks`))
      .then(res => commit("SET_BANKLIST", res.payload))
      .catch(err => {
        commit("SET_BANKLIST", null);
        state.vm.Alert(
          "Banka Listesi Alınamadı. Lütfen daha sonra tekrar deneyiniz."
        );
      });
  },

  get_installments_by_bin: ({}, bin) =>
    api_call_nonbloked(bff().get(`info/finance/bin-info/${bin}`)),

  ...IYSActions({
    perform_sale: ({}, payload) => bff().post("/payment/package/sale", payload)
  })
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
