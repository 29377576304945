import bff, {
  BelgelerEndpoint
} from "../../api/bff";
import IYSActions from "../../misc/IYSActions";

import mergeDeep from "../../misc/mergeDeep";

const DEV = process.env.VUE_APP_ENV === "development";

const state = {
  token: "",

  user: {
    email: "",
    mobile: "",
    tckn: "",
    appType: "" // "EIMZA", "EDEVLET",
  },

  application: {
    appNo: "",
    brandSet: []
  }
};

const getters = {
  getBrands: state => {
    return (
      state.application.brandSet.sort((a, b) =>
        a.props.order > b.props.order ? 1 : -1
      ) || []
    );
  },

  getFiles: state => {
    return state.application.fileSet;
  },

  nextPropsOrder: state => {
    //Markalari siralayabilmek icin props.order kullaniliyor
    if (state.application.brandSet.length === 0) return 0;
    return Math.max(...state.application.brandSet.map(e => e.props.order)) + 1;
  }
};

const mutations = {
  RESET_APPNO: state => {
    state.application = {
      appNo: ""
    };
  },

  SET_TOKEN: (state, value) => {
    state.token = value;
  },

  SET_MOBILE: (state, value) => {
    state.user.mobile = value;
  },

  SET_APPLICATION: (state, application) => {
    for (let index = 0; index < application.brandSet.length; index++) {
      const element = application.brandSet[index];
      element.props = JSON.parse(element.props);
      element.ui = {
        file: null,
        deleted: false,
        input: false,
        deleteAnim: false,
        fileUpdated: false
      };
    }
    state.application = {
      ...application
    };
  },

  SET_ONAY_ADIMI: (state, value) => {
    state.application.step = value;
  },

  MERGE_TO_APPLICATION: (state, payload) => {
    state.application = {
      ...mergeDeep(state.application, payload)
    };
  },

  SET_SMS_ONAYI: (state, value = true) => {
    state.application.confirmation.sms = value;
  },

  SET_BELGE_LISTESI: (state, fileSet) => {
    state.application.fileSet = fileSet;
  }
};

const actions = {
  //{commit, dispatch, getters, rootGetters, rootState, state}, payload

  ...IYSActions({

    get_news: ({ state }, page) =>
      bff(state.token).post("/news", { page }),

    get_integrators: ({ }) => bff().get("/integrators"),

    get_new_captcha: ({ }, payload) =>
      bff().post(
        "/captcha/generate" + (DEV ? "?debug=1&pretty=1" : ""),
        payload
      ),

    e_posta_dogrula: ({ state }, payload) =>
      bff(state.token).post("/hspreapp/verify/email", payload),

    create_basvuru: ({ state }, payload) =>
      bff(state.token).post("/hspreapp/form/create", payload),

    find_basvuru: ({ }, payload) => bff().post("/hspreapp/form/find ", payload),

    get_il_listesi: ({ }) => bff().get("/info/address/cities"),

    get_ilce_listesi: ({ }, city_id) =>
      bff().get("/info/address/towns/" + city_id),

    get_mahalle_listesi: ({ }, district_id) =>
      bff().get("/info/address/districts/" + district_id),

    get_vergi_dairesi: ({ }, city_name) =>
      bff().get("/info/finance/tax-offices/" + city_name),

    save_basvuru: ({ state }, payload) => bff(state.token).post(`/hspreapp/form/save`, payload),

    mernis_dogrula: ({
      state
    }, id_serial_no) =>
      bff(state.token).post(`/hspreapp/verify/mernis`, {
        appNo: state.application.appNo,
        idSerialNo: id_serial_no
      }),

    mersis_dogrula: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/verify/mersis`, {
        appNo: state.application.appNo,
        mersisNo: state.application.mersisNo,
        tckn: state.application.contact.tcKimlikNo
      }),

    sms_kod_olustur: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/generate/sms-code`, {
        appNo: state.application.appNo,
        cellPhone: state.application.contact.cellPhone
      }),

    sms_kod_dogrula: ({
      state
    }, payload) =>
      bff(state.token).post(`/hspreapp/verify/sms-code`, {
        appNo: state.application.appNo,
        cellPhone: state.application.contact.cellPhone,
        ...payload
      }),

    /**
     * sms_kod doğrulama olduğunda application otomatik doğrulanıyor. Bu yüzden "application_dogrula" gereksiz artık
     */
    // application_dogrula: ({ state }) =>
    //   bff().post(`/verify/application`, {
    //     appNo: state.application.appNo
    //   }),

    on_basvuru_onayla: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/approve/initial-submit`, {
        appNo: state.application.appNo
      }),

    // branding_done: ({}, payload) =>
    //   bff().post(`/hspreapp/approve/branding-done`, payload),

    //payload : {path:'/dosya/imza-sirkuleri', file:FileObject}
    belge_yukle: ({
      state
    }, payload) => {
      var formData = new FormData();
      formData.append("file", payload.file);
      formData.append("appNo", state.application.appNo);
      formData.append("brandName", payload.brandName);
      return BelgelerEndpoint(state.token).post(`/hspreapp/${payload.path}`, formData);
    },

    //payload : {path:'/dosya/imza-sirkuleri', file:FileObject}
    marka_ekle: ({
      state,
      getters
    }, payload) => {
      if (payload.ekleType === "NEW")
        payload.props.order = getters.nextPropsOrder; //Marka Siralamasi icin yazildi

      payload.props.voice = parseInt(payload.props.voice);
      payload.props.text = parseInt(payload.props.text);
      payload.props.mail = parseInt(payload.props.mail);

      payload.props.prevSendMessages = parseInt(payload.props.prevSendMessages);
      payload.props.prevNewPhoneNumbers = parseInt(
        payload.props.prevNewPhoneNumbers
      );
      payload.props.prevRejectPhoneNumbers = parseInt(
        payload.props.prevRejectPhoneNumbers
      );

      var formData = new FormData();
      formData.append("appNo", state.application.appNo);
      formData.append("master", payload.master);
      formData.append("brandName", payload.brandName);
      formData.append("brandId", payload.brandId);
      formData.append("props", JSON.stringify(payload.props));
      formData.append("fileUpdated", payload.fileUpdated);
      formData.append("file", payload.file);
      return BelgelerEndpoint(state.token).post(
        `/hspreapp/file/marka-tescil-belgesi`,
        formData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data"
        }
      }
      );
    },

    //payload : {id:12, file:FileObject}
    // belge_degistir: ({ state }, payload) => {
    //   var formData = new FormData();
    //   formData.append("newFile", payload.file);
    //   formData.append("appNo", state.application.appNo);
    //   formData.append("oldFileId", payload.id);
    //   return BelgelerEndpoint().post(`/hspreapp/file/replace`, formData);
    // },

    belge_sil: ({
      state
    }, file_id) =>
      bff(state.token).post(`/hspreapp/file/delete-by-id`, {
        appNo: state.application.appNo,
        fileId: file_id
      }),

    marka_sil: ({
      state
    }, file_id) =>
      bff(state.token).post(`/hspreapp/file/delete-by-brand-id`, {
        appNo: state.application.appNo,
        fileId: file_id
      }),

    belge_sil_by_type: ({
      state
    }, docType) =>
      bff(state.token).post(`/hspreapp/file/delete-by-type`, {
        appNo: state.application.appNo,
        docType
      }),

    belge_listesi_cek: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/file/list`, {
        appNo: state.application.appNo
      }),

    belgeleri_onayla: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/approve/documents`, {
        appNo: state.application.appNo
      }),

    //Sonuc ozet sayfasinda duzenlenmis belgeleri tekrar agent onayina gonderir
    belgeleri_tekrar_onayla: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/approve/documents-edited`, {
        appNo: state.application.appNo
      }),

    basvuruyu_onayla: ({
      state
    }) =>
      bff(state.token).post(`/hspreapp/approve/final-submit`, {
        appNo: state.application.appNo
      })
  })
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
