import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import applicant from './modules/applicant'
import purchase from './modules/purchase'

Vue.use(Vuex)

const debug = false; //process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    app,
    applicant,
    purchase
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})