import bff from "../../api/bff";
import IYSActions from "../../misc/IYSActions";
import Vue from "vue";

const state = {
  show_basvuru: true,
  showDuyuru: true,
  modalCloseable: true,
  modalName: "",
  modalOptions: null,
  notifyRequesterType: 0, // [0]=>Vatandaş  [1]=> Hizmet Sağlayıcı
  lastOpenedModalName: ""
};

const actions = {
  ...IYSActions({
    notify_me: (_, payload) => bff().post("/contact-us/notify-me", payload),
    contact_us: (_, payload) => bff().post("/contact-us/contact-us", payload),
    sowreap_query: (_, payload) => bff().post("/consent-clarification", payload),
    sss_improve: (_, payload) => bff().post("/sss/improve", payload),
  }),

  friction: async () => {
    let loader = Vue.$loading.show();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        loader.hide();
        resolve("foo");
      }, Math.random() * 1000 + 1500);
    });
  },

  friction2: async () => {
    let loader = Vue.$loading.show();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        loader.hide();
        resolve("foo");
      }, Math.random() * 100 + 200);
    });
  }
};

const mutations = {
  HIDE_DUYURU: (state) => {
    state.showDuyuru = false;
  },
  SET_MODAL: (state, val) => {
    state.modalName = val;
    if (val) {
      state.lastOpenedModalName = val;
    }
  },
  SET_MODAL_OPTIONS: (state, val) => {
    state.modalOptions = val;
  },
  SET_NOTIFY_REQUESTER_TYPE: (state, val) => {
    state.notifyRequesterType = val;
  },
  SET_MODAL_CLOSEABLE: (state, val) => {
    state.modalCloseable = val;
  },
  CLOSE_MODAL: state => {
    if (state.modalCloseable) {
      state.modalName = "";
    } else {
      console.warn("Modal pencere kapatılabilir durumda değil!!!");
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
